var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rules-command-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.$te(_vm.module.model.pageHelpMessageKey) ? _vm.$t(_vm.module.model.pageHelpMessageKey) : null},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('module-enable-switch',{model:{value:(_vm.module.model),callback:function ($$v) {_vm.$set(_vm.module, "model", $$v)},expression:"module.model"}}),(_vm.module.model.config.enabled)?[_c('a-divider'),_c('multi-message-editor-with-media-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'rules',
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'placeholders': _vm.BASE_PLACEHOLDERS,
            'targetToUpload': _vm.groupUploadTarget,
            'buttonsHelpView': _vm.EditorButtonsHelpView,
            'editorMode': _vm.MediaEditorMode.TelegramBase,
            'hasAnchor': true,
            'hasMedia': true
          }
        }}})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }